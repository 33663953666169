import { default as _91claimLine_93xuTFza4zdiMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue?macro=true";
import { default as index8q9KF5VihXMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue?macro=true";
import { default as _91claimId_93NPIZILduI4Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue?macro=true";
import { default as _91claimId_93Kl2KNKZupTMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue?macro=true";
import { default as _91connectorId_93i1YP7rvS1fMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as app_45statuswN5ecvZm6XMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/app-status.vue?macro=true";
import { default as indexQOXwgjDSrlMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/index.vue?macro=true";
import { default as auth_45callback852wOlvwczMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginj4HtcCjkEiMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statusPp8tL2HSytMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: _91connectorId_93i1YP7rvS1fMeta?.name ?? "connectorId",
    path: _91connectorId_93i1YP7rvS1fMeta?.path ?? "/:connectorId()",
    meta: _91connectorId_93i1YP7rvS1fMeta || {},
    alias: _91connectorId_93i1YP7rvS1fMeta?.alias || [],
    redirect: _91connectorId_93i1YP7rvS1fMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue").then(m => m.default || m),
    children: [
  {
    name: _91claimId_93NPIZILduI4Meta?.name ?? undefined,
    path: _91claimId_93NPIZILduI4Meta?.path ?? ":claimId()",
    meta: _91claimId_93NPIZILduI4Meta || {},
    alias: _91claimId_93NPIZILduI4Meta?.alias || [],
    redirect: _91claimId_93NPIZILduI4Meta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue").then(m => m.default || m),
    children: [
  {
    name: _91claimLine_93xuTFza4zdiMeta?.name ?? "connectorId-claimId-claimLine",
    path: _91claimLine_93xuTFza4zdiMeta?.path ?? ":claimLine()",
    meta: _91claimLine_93xuTFza4zdiMeta || {},
    alias: _91claimLine_93xuTFza4zdiMeta?.alias || [],
    redirect: _91claimLine_93xuTFza4zdiMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue").then(m => m.default || m)
  },
  {
    name: index8q9KF5VihXMeta?.name ?? "connectorId-claimId",
    path: index8q9KF5VihXMeta?.path ?? "",
    meta: index8q9KF5VihXMeta || {},
    alias: index8q9KF5VihXMeta?.alias || [],
    redirect: index8q9KF5VihXMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91claimId_93Kl2KNKZupTMeta?.name ?? "connectorId-claim-lab-claimId",
    path: _91claimId_93Kl2KNKZupTMeta?.path ?? "claim-lab/:claimId()",
    meta: _91claimId_93Kl2KNKZupTMeta || {},
    alias: _91claimId_93Kl2KNKZupTMeta?.alias || [],
    redirect: _91claimId_93Kl2KNKZupTMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: app_45statuswN5ecvZm6XMeta?.name ?? "app-status",
    path: app_45statuswN5ecvZm6XMeta?.path ?? "/app-status",
    meta: app_45statuswN5ecvZm6XMeta || {},
    alias: app_45statuswN5ecvZm6XMeta?.alias || [],
    redirect: app_45statuswN5ecvZm6XMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/app-status.vue").then(m => m.default || m)
  },
  {
    name: indexQOXwgjDSrlMeta?.name ?? "index",
    path: indexQOXwgjDSrlMeta?.path ?? "/",
    meta: indexQOXwgjDSrlMeta || {},
    alias: indexQOXwgjDSrlMeta?.alias || [],
    redirect: indexQOXwgjDSrlMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/index.vue").then(m => m.default || m)
  },
  {
    name: auth_45callback852wOlvwczMeta?.name ?? "auth-callback",
    path: auth_45callback852wOlvwczMeta?.path ?? "/auth/callback",
    meta: auth_45callback852wOlvwczMeta || {},
    alias: auth_45callback852wOlvwczMeta?.alias || [],
    redirect: auth_45callback852wOlvwczMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue").then(m => m.default || m)
  },
  {
    name: auth_45loginj4HtcCjkEiMeta?.name ?? "auth-login",
    path: auth_45loginj4HtcCjkEiMeta?.path ?? "/auth/login",
    meta: auth_45loginj4HtcCjkEiMeta || {},
    alias: auth_45loginj4HtcCjkEiMeta?.alias || [],
    redirect: auth_45loginj4HtcCjkEiMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue").then(m => m.default || m)
  },
  {
    name: auth_45statusPp8tL2HSytMeta?.name ?? "auth-status",
    path: auth_45statusPp8tL2HSytMeta?.path ?? "/auth/status",
    meta: auth_45statusPp8tL2HSytMeta || {},
    alias: auth_45statusPp8tL2HSytMeta?.alias || [],
    redirect: auth_45statusPp8tL2HSytMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue").then(m => m.default || m)
  }
]